var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(" New private account "),_c('v-spacer'),_c('Button',{attrs:{"icon":"","text":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('ValidationObserver',{ref:"privateProfile",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"upload-background-cropper-wrapper"},[_c('cropper',{ref:"backgroundCropper",staticClass:"upload-background-cropper",attrs:{"src":_vm.backgroundImage,"stencil-props":{
										handlers: {},
										movable: false,
										scalable: false,
										aspectRatio: 401/210,
									},"image-restriction":"stencil","default-boundaries":"fill","defaultBoundaries":function (){ return ({ width: 800, height: 385 }); }}}),_c('Button',{staticStyle:{"position":"absolute","top":"15px","right":"15px"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$refs.backgroundFile.click()}}},[_c('input',{ref:"backgroundFile",staticClass:"d-none",attrs:{"type":"file","accept":".png, .jpg, .jpeg"},on:{"change":function($event){return _vm.loadImage($event, "background")}}}),_c('span',{staticClass:"text-subtitle-1"},[_c('svg-icon',{attrs:{"icon":"camera"}})],1)]),_c('div',{staticClass:"upload-profile-cropper-wrapper"},[_c('cropper',{ref:"profileCropper",staticClass:"upload-profile-cropper",attrs:{"stencil-component":"circle-stencil","src":_vm.profileImage,"stencil-props":{
											handlers: {},
											movable: false,
											scalable: false,
											aspectRatio: 1,
										},"image-restriction":"stencil","defaultBoundaries":function (){ return ({ width: 330, height: 330 }); }}}),_c('Button',{staticStyle:{"position":"absolute","bottom":"10px","right":"0px"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$refs.profileFile.click()}}},[_c('input',{ref:"profileFile",staticClass:"d-none",attrs:{"type":"file","accept":".png, .jpg, .jpeg"},on:{"change":function($event){return _vm.loadImage($event, "profile")}}}),_c('span',{staticClass:"text-subtitle-1"},[_c('svg-icon',{attrs:{"icon":"camera"}})],1)])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldValidation',{attrs:{"rules":"required","label":"Display name"},model:{value:(_vm.display_name),callback:function ($$v) {_vm.display_name=$$v},expression:"display_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldValidation',{attrs:{"rules":"required|email","label":"E-mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldValidation',{attrs:{"label":"@at_name"},model:{value:(_vm.at_name),callback:function ($$v) {_vm.at_name=$$v},expression:"at_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldValidation',{attrs:{"rules":"required|min:8","type":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',[_c('VTextareaValidation',{attrs:{"label":"Bio","rows":"1"},model:{value:(_vm.bio),callback:function ($$v) {_vm.bio=$$v},expression:"bio"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-spacer'),_c('Button',{attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(" Create ")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }