<template>
	<v-dialog
		v-model="show"
		width="500"
		@keydown.esc="cancel"
		persistent
	>
		<v-card>
			<v-card-title>
				New public account
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="publicProfile"
				@submit.prevent="create"
			>
				<form autocomplete="off">
					<div class="upload-background-cropper-wrapper">
						<cropper
							class="upload-background-cropper"
							:src="backgroundImage"
							ref="backgroundCropper"
							:stencil-props="{
											handlers: {},
											movable: false,
											scalable: false,
											aspectRatio: 401/210,
										}"
							image-restriction="stencil"
							default-boundaries="fill"
							:defaultBoundaries="()=> ({ width: 800, height: 385 })"
						></cropper>
						<Button
							@click="$refs.backgroundFile.click()"
							style="position:absolute;top:15px;right:15px;"
							fab
							small
							color="primary"
						>
							<input type="file" ref="backgroundFile" @change="loadImage($event, `background`)" accept=".png, .jpg, .jpeg" class="d-none">
							<span class="text-subtitle-1"><svg-icon icon="camera" /></span>
						</Button>

						<div class="upload-profile-cropper-wrapper">
							<cropper
								class="upload-profile-cropper"
								stencil-component="circle-stencil"
								:src="profileImage"
								ref="profileCropper"
								:stencil-props="{
												handlers: {},
												movable: false,
												scalable: false,
												aspectRatio: 1,
											}"
								image-restriction="stencil"
								:defaultBoundaries="()=> ({ width: 330, height: 330 })"
							/>

							<Button
								@click="$refs.profileFile.click()"
								style="position:absolute;bottom:10px;right:0px;"
								fab
								small
								color="primary"
							>
								<input type="file" ref="profileFile" @change="loadImage($event, `profile`)" accept=".png, .jpg, .jpeg" class="d-none">
								<span class="text-subtitle-1"><svg-icon icon="camera" /></span>
							</Button>
						</div>
					</div>

					<v-divider />

					<v-card-text class="pa-6 pt-0">
						<v-row>
							<v-col cols="6">
								<VTextFieldValidation
									rules="required"
									v-model="display_name"
									label="Display name"
								/>
							</v-col>

							<v-col cols="6">
								<VTextFieldValidation
									rules="required"
									v-model="at_name"
									label="@at_name"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="6">
								<VTextFieldValidation
									v-model="domain"
									label="Domain"
								/>
							</v-col>

							<v-col cols="6">
								<VSelectValidation
									rules="required"
									v-model="public_type"
									label="Type"
									:items="[{ name: 'Organisation', value: 'brand' }, { name: 'Public figure', value: 'person' }]"
								/>
							</v-col>
						</v-row>

						<v-row v-if="$authorized([`manager`,`marketing`])">
							<v-col cols="6">
								<v-switch
									v-model="verified"
									label="Verified"
									hide-details="auto"
								/>
							</v-col>

							<v-col cols="6" v-if="verified && public_type === `brand`">
								<VAutocompleteValidation
									rules="required"
									v-model="admin"
									label="Admin"
								></VAutocompleteValidation>
							</v-col>
						</v-row>

						<v-row v-if="verified && public_type === `person`">
							<v-col cols="6">
								<VTextFieldValidation
									rules="required"
									v-model="credentials.email"
									label="E-mail"
									type="email"
								></VTextFieldValidation>
							</v-col>

							<v-col cols="6">
								<VTextFieldValidation
									rules="required"
									v-model="credentials.password"
									label="Password"
									type="password"
								></VTextFieldValidation>
							</v-col>
						</v-row>

						<v-row v-if="verified">
							<v-col>
								<VTextareaValidation
									v-model="bio"
									label="Bio"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-spacer />
								<Button
									type="submit"
									:loading="loading"
								>
									Create
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>
		</v-card>
	</v-dialog>
</template>

<style type="scss">
.upload-background-cropper-wrapper {
	position:relative;
	height: 220px;
	width: 100%;
}

.upload-background-cropper {
	height: 100%;
	width: 100%;
	overflow:hidden;
	background: #eee;
}

.upload-profile-cropper-wrapper {
	position:absolute;
	bottom:20px;
	left:20px;
}

.upload-profile-cropper {
	border: solid 1px #EEE;
	border-radius: 50%;
	height: 110px;
	width: 110px;
	overflow:hidden;
	background: #e3e3e3;
}
</style>

<script>
import { globalMixIn } from '@/App'
import { Cropper } from 'vue-advanced-cropper'
import VTextFieldValidation from '@/components/validation/VTextField'
import VTextareaValidation from '@/components/validation/VTextarea'
import VAutocompleteValidation from '@/components/validation/VAutocomplete'
import VSelectValidation from '@/components/validation/VSelect'

export default {
	components: {
		VTextFieldValidation,
		VTextareaValidation,
		VAutocompleteValidation,
		VSelectValidation,
		Cropper,
	},
	computed: {
		form() {
			let data = {
				public_user: {
					at_name: this.at_name,
					display_name: this.display_name,
					type: `public`,
					background_image: this.background_image,
					public_type: this.public_type,
					profile_image: this.profile_image,
					small_profile_image: this.small_profile_image,
					domain: this.domain,
				},
			}

			if (this.credentials.email && this.credentials.password) {
				data.credentials = this.credentials
			}

			if (this.admin) data.admin_at_name = this.admin.at_name

			return data
		},
	},
	data() {                                   // <== changed this line
		return {
			show: false,
			loading: false,
			profileImage: null,
			backgroundImage: null,
			verified: false,
			token: null,
			credentials: {
				email: null,
				password: null,
			},

			admin: null,
			at_name: null,
			display_name: null,
			type: `public`,
			public_type: `brand`,
			profile_image: null,
			small_profile_image: null,
			domain: null,
			bio: ``,
		}
	},
	name: `NewPublicProfile`,
	methods: {
		open() {
			this.reset()
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.reset()
		},
		accept() {
			this.loading = false
			this.resolve()
			this.close()
		},
		cancel() {
			this.reject
			this.close()
		},
		create() {
			this.$refs.publicProfile.validate().then(valid => {
				if (!valid) return
				this.loading = true


				const promise1 = new Promise((resolve, reject) => this.uploadImage(resolve, reject, `profile`))
				const promise2 = new Promise((resolve, reject) => this.uploadImage(resolve, reject, `background`))

				Promise.all([promise1, promise2]).then(() => {
					this.$httpInt.post(`/v2/app/account`, this.form)
						.then(() => {
							if (this.verified) {
								this.$httpInt.patch(`/v2/app/account/${this.at_name}`, {
									bio: this.bio,
								})
									.then(() => {
										this.$root.$snackbar(`Public account created`)
										this.accept()
									})
							} else {
								this.$root.$snackbar(`Public account created`)
								this.accept()
							}
						})
				})
			})
		},
		defaultSize(o) {
			return {
				width: o.imageWidth,
				height: o.imageWidth/(401/210),
			};
		},
		defaultSizeCircle(o) {
			return {
				width: o.imageWidth*2,
				height: o.imageHeight*2,
			};
		},
		loadImage(event, target) {
			const input = event.target
			if (input.files && input.files[0]) {
				const reader = new FileReader()
				reader.onload = (e) => {
					if (target === `background`) this.backgroundImage = e.target.result
					if (target === `profile`) this.profileImage = e.target.result
				}
				reader.readAsDataURL(input.files[0])
			}
		},
		uploadImage(resolve, reject, target) {
			if (target === `profile` && !this.profileImage) {
				resolve()
				return
			}
			if (target === `background` && !this.backgroundImage) {
				resolve()
				return
			}

			const vueImage = target === `profile` ? this.$refs.profileCropper : this.$refs.backgroundCropper
			this.$uploadImage(vueImage).then(res => {
				if (target === `background`) this.background_image = res.result.thumbnails.original.url
				if (target === `profile`) this.profile_image = res.result.thumbnails.original.url
				resolve()
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
	props: [`profile`],
	mixins: [globalMixIn],
}
</script>