<template>
	<v-container
			fluid
	>
		<NewPublicProfile ref="newPublicProfile" />
		<NewPrivateProfile ref="newPrivateProfile" />
		<NewMultipleProfile ref="newMultipleProfile" />

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-row>
							<v-col>
								<v-radio-group @change="refreshContent" v-model="accountType" row>
									<v-radio
										label="Private"
										value="private"
									></v-radio>
									<v-radio
										label="Public"
										value="public"
									></v-radio>
								</v-radio-group>
							</v-col>
							<v-col align="right">
								<v-menu offset-y v-if="$authorized([`manager`])">
									<template v-slot:activator="{ on }">
										<Button v-on="on" >
											<v-icon>mdi-download</v-icon>
										</Button>
									</template>
									<v-list>
										<v-list-item
											v-for="(item, index) in download_items"
											:key="index"
											@click="download(item.event)"
										>
											<v-list-item-title>{{ item.title }}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-menu offset-y>
									<template v-slot:activator="{ on }">
										<Button v-on="on" class="ml-4">
											<v-icon>mdi-plus</v-icon>
										</Button>
									</template>
									<v-list>
										<v-list-item @click="openNewPrivate" v-if="$authorized([`manager`,`marketing`])">
											<v-list-item-title>Private</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openNewPublic">
											<v-list-item-title>Public</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openNewMultiple">
											<v-list-item-title>Multiple</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-col>
						</v-row>

						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:search="search"
								:options.sync="options"
								class="row-pointer mt-0"
								@click:row="goToProfile"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col>
										<v-text-field no-gutters clearable v-model="search" label="Search profiles" class="ps-4 pt-0 pr-4"></v-text-field>
									</v-col>
								</v-row>

							</template>
							<template v-slot:[`item.creation_date`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.creation_date).format(`YYYY-MM-DD HH:mm`) }}</span>
							</template>
							subscribers
							<template v-slot:[`item.verified`]="{ item }">
								<v-icon v-if="item.verified" color="green">mdi-check-bold</v-icon>
							</template>
							<template v-slot:[`item.published`]="{ item }">
								<v-icon v-if="item.published" color="green">mdi-check-bold</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'
	import NewPrivateProfile from "@/components/dialogs/NewPrivateProfile"
	import NewPublicProfile from "@/components/dialogs/NewPublicProfile"
	import NewMultipleProfile from "@/components/dialogs/NewMultipleProfile"

	export default {
		components: {
			NewPublicProfile,
			NewPrivateProfile,
			NewMultipleProfile,
		},
		data: () => ({
			loading: false,
			accountType: `private`,
			download_items: [
				{ title: `News Subscribers`, event: `subscribers` },
				{ title: `Private accounts`, event: `privateAccounts` },
			],
			search: '',
			options: {
				sortDesc: [true],
				sortBy: [`creation_date`],
			},
			headers: [
				{ text: `Name`, value: `display_name` },
				{ text: 'User Name', value: `at_name` },
				{ text: `Type`, value: `type` },
				{ text: `Created`, value: `creation_date` },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			goToProfile(value) {
				this.$router.push({name: `Profile`, params: {id: value.at_name}})
			},
			cancel() {
			},
			openNewPublic() {
				this.$refs.newPublicProfile.open()
			},
			openNewPrivate() {
				this.$refs.newPrivateProfile.open()
			},
			openNewMultiple() {
				this.$refs.newMultipleProfile.open()
			},
			download (e) {
				if (e === `subscribers`) {
					this.$httpInt.get(`wdht/admin/users/newsletter-consumers`)
						.then(res => {
							let csv = `Name,E-mail,Subscribed\n`
							res.data.forEach(function(row) {
								csv += Object.values(row).join(',')
								csv += "\n";
							})
							const link = document.createElement("a")
							link.setAttribute(`href`, `data:text/csv;charset=utf-8,` + encodeURI(csv))
							link.setAttribute(`download`, `subscribers.csv`)
							link.click()
						})
				}

				if (e === `privateAccounts`) {
					this.fetchPrivateAccounts(1)
				}
			},
			fetchPrivateAccounts(page) {
				if (page) return
				/*
				let per_page = 4000;
				this.$httpInt.get(`/profiles/list/private?page=${page}&per_page=${per_page}`)
					.then(res => {
						if (res.data.result.length !== 0) {
							let csv = `Name,Created,E-mail,Type\n`
							res.data.result.forEach(function(row) {
								csv += Object.values(row).join(',')
								csv += `\n`;
							})
							const link = document.createElement("a")
							link.setAttribute(`href`, `data:text/csv;charset=utf-8,` + encodeURI(csv))
							link.setAttribute(`download`, `private_profiles.csv`)
							link.click()

							this.fetchPrivateAccounts((page+1))
						}
					})
				 */
			},
			refreshContent () {
				this.loading = true
				this.$httpInt.get(`/v2/app/account`, { params: this.$paginationParams(this, { type: this.accountType }) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		mixins: [globalMixIn],
		name: `CommunityProfiles`,
		watch: {
			search: {
				handler () {
					this.$debounce(() => this.refreshContent(), 300)
				},
			},
			options: {
				handler () {
					this.refreshContent()
				},
			},
		},
	}
</script>
