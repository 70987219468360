<template>
	<v-dialog
		v-model="show"
		@keydown.esc="cancel"
		persistent
		scrollable
	>
		<v-card>
			<v-card-title>
				Create Multiple Accounts
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-card-text class="pa-0 ma-0">
				<v-data-table
					:headers="headers"
					:items="items"
					hide-default-footer
					class="elevation-1">
					<template v-slot:body="{ items, headers }">
						<tbody>
						<tr v-for="(item,idx) in items" :key="idx">
							<td v-for="(header,key) in headers" :key="key">
								<template v-if="header.value === `type`">
									{{ item.type }}
								</template>
								<template v-else-if="header.value === `profile_image`">
									<div style="height: 64px; width: 64px;" class="ma-2">
										<VImage ref="profile_image" mode="profile" />
									</div>
								</template>
								<template v-else-if="header.value === `background_image`">
									<div style="height: 64px; width: 181px;" class="ma-2">
										<VImage ref="background_image" mode="background" />
									</div>
								</template>
								<template v-else-if="header.value === `actions`">
									<v-btn small icon @click="addValue(idx)">
										<svg-icon color="green" icon="plus" />
									</v-btn>
									<v-btn v-if="items.length > 1 && false" small icon @click="removeValue(idx)">
										<svg-icon color="red" icon="minus" />
									</v-btn>
								</template>
								<template v-else>
									<v-edit-dialog
										:return-value.sync="item[header.value]"
										@save="saveValue"
									> {{item[header.value]}}
										<template v-slot:input>
											<v-text-field
												v-model="item[header.value]"
												label="Edit"
												single-line
											></v-text-field>
										</template>
									</v-edit-dialog>
								</template>
							</td>
						</tr>
						</tbody>
					</template>
				</v-data-table>
			</v-card-text>

			<v-card-actions v-if="!created">
				<v-container>
					<v-btn @click="create" :loading="loading">
						Create
					</v-btn>
				</v-container>
			</v-card-actions>

		</v-card>
	</v-dialog>
</template>

<script>
import VImage from '@/components/material/Image'

export default {
	components: {
		VImage,
	},
	data() {
		return {
			show: false,
			headers: [
				{ text: `Type`, value: `type` },
				{ text: `Public type`, value: `public_type` },
				{ text: `Display name`, value: `display_name` },
				{ text: `Profile`, value: `profile_image` },
				{ text: `Background`, value: `background_image` },
				{ text: `At name`, value: `at_name` },
				{ text: `Email`, value: `email` },
				{ text: `Password`, value: `password` },
				{ text: `Bio`, value: `bio` },
				{ value: `actions`, sortable: false },
			],
			items: [],
			loading: false,
			created: false,
		}
	},
	name: `NewMultipleProfile`,
	methods: {
		open() {
			this.reset()
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.addValue()
			})
		},
		close() {
			this.reset()
		},
		accept() {
			this.resolve()
			this.close()
		},
		cancel() {
			this.reject()
			this.close()
		},
		create() {
			this.loading = true
			
			this.items.forEach((o, i) => {

				const promise1 = this.$refs.profile_image[i].uploadImage()
				const promise2 = this.$refs.background_image[i].uploadImage()

				Promise.allSettled([promise1, promise2]).then(res => {

					let data = {
						//admin_username: "username@test.com",
						//admin_at_name: "username",
						public_user: {
							at_name: o.at_name,
							display_name: o.display_name,
							type: o.type,
							public_type: o.public_type,
							//domain: "string"
						}
					}

					// Email and Password
					if (o.email && o.password) {
						data.credentials = {
							email: o.email,
							password: o.password,
						}
					}

					// Profile image
					if (res[0].status === `fulfilled`) {
						data.public_user.profile_image = res[0].value.result.thumbnails.original.url
						data.public_user.small_profile_image = res[0].value.result.thumbnails.icon.url
					}

					// Background image
					if (res[1].status === `fulfilled`) {
						data.public_user.background_image = res[1].value.result.thumbnails.original.url
					}

					// Bio
					if (o.bio) {
						data.public_user.bio = o.bio
					}

					this.$httpInt.post(`/v2/app/account`, data)
						.then(() => {})
				}).finally(() => this.created = true)
			})
		},
		addValue(index) {
			const account = {
				type: `public`,
				public_type: `person`,
			}

			if (Number.isInteger(index)) this.items.splice( index + 1, 0, account)
			else this.items.push(account)
		},
		saveValue() {
		},
		removeValue(index) {
			this.items.splice(index,1);
		},
		loadImage(event, target) {
			const input = event.target
			if (input.files && input.files[0]) {
				const reader = new FileReader()
				reader.onload = (e) => {
					if (target === `background`) this.backgroundImage = e.target.result
					if (target === `profile`) this.profileImage = e.target.result
				}
				reader.readAsDataURL(input.files[0])
			}
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
			this.$nextTick(() => this.$refs.privateProfile && this.$refs.privateProfile.reset())
		},
	},
}
</script>