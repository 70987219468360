<template>
	<ValidationProvider
		:name="$attrs.label"
		:rules="rules"
		:vid="$attrs.vid"
		v-slot="{ errors }"
		mode="eager"
	>
		<v-select
			v-model="innerValue"
			:items="items"
			:label="$attrs.label"
			v-bind="$attrs"
			v-on="$listeners"
			:error-messages="errors"
			data-lpignore="true"
			hide-details="auto"
			item-text="name"
			item-value="value"
			placeholder=" "
		></v-select>
	</ValidationProvider>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => []
		},
		rules: {
			type: [Object, String],
			default: ""
		},
		value: {
			type: null
		}
	},
	data: () => ({
		innerValue: "",
	}),
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit("input", newVal)
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal
		}
	},
}
</script>