<template>
	<ValidationProvider
		:name="$attrs.label"
		:rules="rules"
		:vid="$attrs.vid"
		v-slot="{ errors }"
		mode="eager"
	>
		<Input
			v-model="innerValue"
			:error-messages="errors"
			v-bind="$attrs"
			v-on="$listeners"
		/>
	</ValidationProvider>
</template>

<script>
export default {
	props: {
		rules: {
			type: [Object, String],
			default: ``
		},
		// must be included in props
		value: {
			type: null
		}
	},
	data: () => ({
		innerValue: ``
	}),
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit(`input`, newVal)
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal
		}
	},
	created() {
		if (typeof this.value !== `undefined`) {
			this.innerValue = this.value
		}
	},
}
</script>