<template>
	<ValidationProvider
		:name="$attrs.label"
		:rules="rules"
		v-slot="{ errors }"
		mode="eager"
	>
		<v-autocomplete
			:value="innerValue"
			:label="$attrs.label"
			:items="result"
			:search-input.sync="search"
			no-filter
			v-bind="$attrs"
			v-on="$listeners"
			:error-messages="errors"
			placeholder=" "
			autocomplete="off"
			:menu-props="{ maxWidth: `468px`, closeOnContentClick: true }"
			data-lpignore="true"
			clearable
			@click:clear="innerValue = ``"
			hide-details="auto"
		>
			<template slot="selection" slot-scope="{ item }">
				@{{ item.at_name }}
			</template>
			<template slot="item" slot-scope="{ item }">
				<v-list-item
					:key="item._id"
					@click="selectItem(item)"
				>
					<v-list-item-content>
						<v-list-item-subtitle class="font-weight-bold">{{ item.display_name }}</v-list-item-subtitle>
						<v-list-item-subtitle>{{ item.display_name }} @{{ item.at_name }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-autocomplete>
	</ValidationProvider>
</template>

<script>
import { globalMixIn } from '@/App.vue'

export default {
	props: {
		rules: {
			type: [Object, String],
			default: ""
		},
		// must be included in props
		value: {
			type: null
		}
	},
	data: () => ({
		innerValue: "",
		result: [],
		search: '',
	}),
	methods: {
		makeSearch(value) {
			if (!value) this.result = []
			this.$httpInt.get(`/v2/app/account/?q=${value}&type=private`)
				.then(res => this.result = res.data.result)
		},
		selectItem(item) {
			this.innerValue = item
		},
	},
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit("input", newVal)
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal
		},
		search(value) {
			value && this.$debounce(() => this.makeSearch(value), 300)
		},
	},
	created() {
		if (this.value) {
			this.innerValue = this.value
		}
	},
	mixins: [globalMixIn],
}
</script>